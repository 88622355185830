.privacypolicy a {
  display: inline;
  overflow: hidden; }

.privacypolicy .divTable {
  display: block;
  width: 100%;
  overflow-x: auto; }

.privacypolicy .divTableRow {
  display: table-row; }

.privacypolicy .divTableHeading {
  background-color: #8399B8;
  display: table-header-group; }

.privacypolicy .divTableCell, .privacypolicy .divTableHead {
  border: 1px solid #8399B8;
  display: table-cell;
  padding: 3px 10px; }

.privacypolicy .divTableHeading {
  background-color: #8399B8;
  display: table-header-group;
  font-weight: bold; }

.privacypolicy .divTableFoot {
  background-color: #8399B8;
  display: table-footer-group;
  font-weight: bold; }

.privacypolicy .divTableBody {
  display: table-row-group; }

.privacypolicy .privacy {
  padding-top: 100px;
  padding-bottom: 80px; }

.privacypolicy .title {
  padding-bottom: 60px; }

.privacypolicy .uppercase {
  text-transform: uppercase; }

.privacypolicy .centered {
  text-align: center; }

.privacypolicy .h1 {
  font-size: 18px;
  margin: 0;
  padding-bottom: 5px; }

.privacypolicy .main-content {
  font-size: 14px;
  max-width: 920px;
  padding: 0 40px;
  margin: 0 auto;
  text-align: justify;
  padding-bottom: 50px; }

.privacypolicy .bold {
  font-weight: 600; }

.privacypolicy .italic {
  font-style: italic; }

.privacypolicy .underline {
  text-decoration: underline; }

.privacypolicy .no-margin {
  margin: 0; }

.privacypolicy .flex-row {
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.privacypolicy .grow {
  -ms-flex-positive: 2;
      flex-grow: 2; }

.privacypolicy .table-header-item {
  border: 1px solid #8399B8;
  padding: 5px 0;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%; }

.privacypolicy .table-item {
  border: 1px solid #8399B8;
  border-top: none;
  padding: 5px;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%; }

.privacypolicy .small-table-header-item {
  -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
  border: 1px solid #8399B8;
  padding: 5px;
  text-align: center; }

.privacypolicy .small-table-item {
  -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
  border: 1px solid #8399B8;
  border-top: none;
  padding: 10px; }

.privacypolicy .no-right-border {
  border-right: none; }

.privacypolicy li {
  padding-bottom: 4px; }

.privacypolicy .content-header {
  max-width: 920px;
  margin: 0 auto; }
  .privacypolicy .content-header .header {
    margin: 108px 0 50px 40px; }
    .privacypolicy .content-header .header h6 {
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 2px;
      line-height: 20px; }
    .privacypolicy .content-header .header h1.medium {
      letter-spacing: 0;
      line-height: 64px;
      font-weight: 400; }
    .privacypolicy .content-header .header p {
      font-size: 18px;
      letter-spacing: 0.2px;
      line-height: 28px;
      font-weight: 400; }
